@import "src/assets/variables";

.container {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

.panelsWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.panelsColumn {
  width: 440px;
}

@media only screen and (max-width: $breakpointTablet) {
  .container {
    max-width: 760px;
    padding: 0 $gapS;
  }

  .panelsColumn {
    width: 100%;
  }
}

.toggleRadioButtons {
  display: flex;
  justify-content: flex-start;
  margin-top: $gapS;
  margin-left: $gapXs;
}

.toggleRadioButton {
  display: flex;
  margin-left: $gapXs;
  color: $colorOptimGray200;
  font-size: $fontS;
  align-items: center;
}
