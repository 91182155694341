@import "src/assets/variables";
@import "src/assets/base/shared.scss";

.cardButton {
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 44px;
  background: $colorBaseWhite;
  color: $colorBaseBlack;
  font-size: $fontM;
  font-weight: $medium;
   &:hover{
     background: $colorBlue;
     color: $colorBaseWhite;
   }
}
.icon {
  margin: 0 $gapXxs;
  line-height: 0;
}
.diamondButtonIcon {
  margin-right: 8px;
  position: relative;
  top: 1px;

  svg {
    height: 28px;
    width: 28px;
  }
}

.cardButtonIcon {
  position: relative;
  margin-left: 8px;
}

.cardsSection {
  margin-top: 30px;
}

.cardsSectionTitle {
  font-size: $fontL;
  font-weight: $semiBold;
  margin-bottom: 10px;
}

.cardsSectionSubtitle {
  font-weight: $regular;
  margin-bottom: 40px;
  color: $colorOptimGray200;
}
