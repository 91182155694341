@import "src/assets/variables";

.title {
  font-weight: $regular;
  font-size: $fontL;
  margin-bottom: 20px; 
}

.subtitle {
  font-size: $fontM;
  color: $colorOptimGray200;
}

.fieldsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 40px;
}

.fieldLabel {
  color: $colorOptimGray200;
  font-size: $fontS;
  line-height: 1.7;
}

.fieldValue {
  font-size: $fontM;
}

.nameValue {
  max-width: 10em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: $fontM;
}

.detailCopy {
  color: $colorBaseWhite;
  cursor: pointer;
  font-size: $fontS;
  &:after {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: 2px;
    content: url("../../../assets/icons/copyID.svg");
  }
}

.nameWrapper {
  padding-left: 40px;
  position: relative;

  &.poolName {
    &:before {
      position: absolute;
      left: 0;
      top: 6px;
      content: url('../../../assets/icons/cardano.svg');
    }
  }
}

.addressWrapper {
  text-align: right;
}

.bondPageLink {
  // &:link {
  color: $colorLightBlue;
  // }
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.toggleRadioButtons {
  display: flex;
  justify-content: flex-start;
  margin-top: $gapS;
  margin-left: $gapXs;
}

.toggleRadioButton {
  display: flex;
  margin-left: $gapXs;
  color: $colorOptimGray200;
  font-size: $fontS;
  align-items: center;
}
