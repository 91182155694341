@import "src/assets/variables";
@import "src/assets/base/shared.scss";

.title {
  margin-bottom: 20px;
  color: $colorBaseWhite;
  font-weight: 600;
}
.list {
  list-style: none;
  margin: 0;
  padding: 0;
  button {
    width: 100%;
    justify-content: flex-start;
    margin-top: 10px;
    font-size: 14px;
    padding: 15px 16px;
  }
}
.icon {
  width: 30px;
  margin-right: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connectButton {
  height: 40px;
  padding: 0 $gapS;
  font-size: $fontS;
}

.headerPanel {
  display: flex;
  flex-direction: row;
  gap: $gapS;
  text-align: left;
  border-radius: 12px;
  background: radial-gradient(86.72% 63.17% at 3.74% 100%, rgba(21, 38, 46, 0.35) 0%, #1A1A25 100%);
  padding: $gapLL;
  align-items: center;

  @media only screen and (max-width: $breakpointTablet) {
    margin-bottom: 16px;
    width: 100%;
  }
}

.headerLink {
  color: $colorLightBlue;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.box {
  padding: 16px 20px;
  border-radius: 24px;
  align-items: center;
  background-color: $colorShade110;
  justify-content: space-between;

  .connectionMark {
    padding: 2px 8px;
    font-size: $fontS;
    height: fit-content;
    background: $bgColorSuccess400;
    color: $colorSuccess400;
    border-radius: 16px;
    line-height: 18px;
  }
}

.inputBox {
  outline: none;
  border: none;
  color: $colorGray200;
  width: auto;
  max-width: 50%;
  font-size: $gapS;
  height: 50px;
}

.relative {
  font-size: 60px;

}

.floatingConnectButton {
  height: 40px;
  width: 200px;
  max-width: 48% !important;
  padding: 16px 20px !important;
  font-size: $fontS;
  float: right;
}

hr {
  border-color: rgb(0 0 0 / 3%);
}

.itemsContainer {
  display: flex;
  gap: 10px;

  .box {
    display: block;
    text-align: center;
    cursor: pointer;
    border-radius: 12px;
    width: 100%;
    p {
      margin: 0px;
    }
    .title {
      font-size: $gapS;
    }
    .label {
      font-size: 14px;
      color: $colorOptimGray200;
      margin-top: 4px;
    }
  }
  .box.active {
    background: $colorBaseWhite;
    p {
      color: $colorOptimGray900;
    }
  }
  .box:hover{
    background: $colorShade100;
  }
}
