@import "src/assets/variables";

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $colorBaseWhite;
  width: 100%;
  height: 100%;
}
