@import "src/assets/variables";

.wrapper {
  .title {
    font-size: $fontL;
    line-height: 30px;
    margin-bottom: 40px;
  }

  .amountInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .amount {
      padding: 10px 20px;
      border-radius: 9999px;
      border: solid 1px $colorShade90;
      width: 200px;
    }

    .info {
      width: 170px;
      text-align: right;

      .label {
        font-size: $fontS;
        color: $colorOptimGray200;
      }

      .infoAmount {
        font-size: $fontXxL;
        line-height: 38px;
      }
    }
  }

  .walletAmount {
    width: 100%;
    padding: 30px 0px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: $colorOptimGray200;
  }

  .button {
    background-color: $colorError600  !important;
    background: none;
  }

  .equity {
    margin-top: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-radius: 24px;
    border: 1px solid $colorShade90;
    background: $colorShade100;
    padding: 30px;

    .label {
      display: flex;
      align-items: center;
      color: $colorOptimGray200;
      line-height: 10px;

      svg {
        margin-right: 20px;
        width: 36px;
        height: 36px;
      }
    }
  }
  .cancelAttention {
    margin-top: 0;
    margin-bottom: $gapS;
  }
}
