@import "src/assets/variables";

.wrapper {

  .title {
    font-size: $fontL;
    line-height: 30px;
  }
  .walletLogos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0px;
  }

  .icon {
    margin: 0px 20px;
  }
  .info {
    border-radius: 24px;
    border: solid 1px $colorShade90;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    padding: 24px 25px 24px 30px;

    font-size: $fontM;

    .change {
      .label {
        color: $colorOptimGray200;
        line-height: 24px;
      }
      .amount {
        margin-top: 5px;
        color: $colorBaseWhite;
        line-height: 24px;
      }
    }

    .exchange {
      display: flex;
      .tokenInfo {
        text-align: right;
        .label {
          color: $colorOptimGray200;
          line-height: 24px;
        }
        .amount {
          margin-top: 5px;
          color: $colorBaseWhite;
          line-height: 24px;
        }
      }
      .exchangeIcon {
        svg {
          width: 50px;
          height: 50px;
          margin-left: 20px;
          flex-shrink: 0;
        }
      }
    }
  }
}
