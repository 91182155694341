@import "src/assets/variables";

.wrapper {
  max-width: 460px;
  .title {
    font-size: $fontL;
    line-height: 30px;
    margin-bottom: 40px;
  }
  .amountInfo {
    margin: 40px 0;
  }
}
