@import "src/assets/variables";

.chartWrapper {
  border-top: 1px solid $colorShade90;
  border-bottom: 1px solid $colorShade90;
  padding-top: 50px;
  padding-bottom: $gapS;
}

.noBorder {
  border-top: none;
  border-bottom: none;
}

.conclusionWraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $fontS;
  font-weight: $regular;
}

.conclusionValue {
  
  &.green {
    color: $colorGreen;
  }

  &.orange {
    color: $colorWarning;
  }

  &.red {
    color: $colorRed;
  }
}

.chartLine {
  display: flex;
  width: 100%;
  height: 12px;
  margin: 14px 0 10px;
  position: relative;
}

.lastPart {
  background: $colorBaseWhite;
  position: relative;
  height: 100%;

  &:after {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    content: '';
    width: 1px;
    height: 20px;
    width: 1px;
    background: $colorBaseWhite;
  }
}

.chartPart {
  position: relative;
  height: 100%;

  &:after {
    display: inline-block;
    position: absolute;
    content: '';
    width: 1px;
    height: 20px;
  }

  &.white {
    background: $colorBaseWhite;
  }

  &.gray {
    background: #575860;
  }

  &.red {
    background: $colorRed;

    &:after {
      display: none;
    }
  }

  &.whiteLeft {
    background: $colorBaseWhite;

    &:after {
      left: 0;
      bottom: 0;
      background: $colorBaseWhite;
    }
  }

  &.whiteRight {
    background: $colorBaseWhite;

    &:after {
      right: 0;
      bottom: 0;
      background: $colorBaseWhite;
    }
  }

  &.grayLeft {
    background: #575860;

    &:after {
      left: 0;
      bottom: 0;
      background: #575860;
    }
  }

  &.grayRight {
    background: #575860;

    &:after {
      right: 0;
      bottom: 0;
      background: #575860;
    }
  }

  &.current {
    text-align: center;
    font-size: $fontXxs;
    font-weight: $semiBold;

    &.red {
      background: $colorRed;

      &:after {
        display: none;
      }
    }

    &.redLeft {
      background: $colorRed;
      
      &:after {
        left: 0;
        bottom: 0;
        background: $colorRed;
      }
    }

    &.redRight {
      background: $colorRed;

      &:after {
        right: 0;
        bottom: 0;
        background: $colorRed;
      }
    }

    &.darkGreen {
      background: $colorDarkGreen;

      &:after {
        display: none;
      }
    }

    &.darkGreenLeft {
      background: $colorDarkGreen;

      &:after {
        left: 0;
        bottom: 0;
        background: $colorDarkGreen;
      }
    }

    &.darkGreenRight {
      background: $colorDarkGreen;

      &:after {
        right: 0;
        bottom: 0;
        background: $colorDarkGreen;
      }
    }

    &.green {
      background: $colorGreen;

      &:after {
        display: none;
      }
    }

    &.greenLeft {
      background: $colorGreen;

      &:after {
        left: 0;
        bottom: 0;
        background: $colorGreen;
      }
    }

    &.greenRight {
      background: $colorGreen;

      &:after {
        right: 0;
        bottom: 0;
        background: $colorGreen;
      }
    }
  }
}

.pointWrapper {
  font-size: $fontXxs;
  color: $colorBaseWhite;
  position: absolute;
  top: -50px;

  &.left {
    text-align: left;
    left: 0;

    &.toSmall {
      width: 65px;
    }
  }

  &.right {
    text-align: right;
    right: 0;
  }

  &.center {
    text-align: center;

    &.left {
      left: 0;
      transform: translate(-50%, 0);
    }

    &.right {
      right: 0;
      transform: translate(50%, 0);
    }
    
    &.toSmall {
      left: 70px;
    }
  }
}

.pointName {
  margin-bottom: 2px;
  font-weight: $regular;
}

.pointValue {
  color: $colorOptimGray200;
  font-weight: $regular;
}

.footnotesWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.footnote {
  display: inline-flex;
  align-items: center;
  font-size: $fontXxs;
  font-weight: $medium;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  &:before {
    content: '';
    display: inline-flex;
    width: 8px;
    height: 8px;
    margin-right: 2px;
    border-radius: 50%;
  }

  &.green {
    &:before {
      background: $colorGreen;
    }
  }
  
  &.darkGreen {
    &:before {
      background: $colorDarkGreen;
    }
  }
  
  &.red {
    &:before {
      background: $colorRed;
    }
  }
}

.footnoteWarning {
  font-size: $fontXxs;
  font-weight: $medium;
  &.green {
    color: $colorGreen;
  }
  &.orange {
    color: $colorWarning;
  }
  &.red {
    color: $colorRed;
  }
}
