@import "src/assets/variables";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $colorBaseBlack;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  &.hidden {
    pointer-events: none;
    display: none;
  }
  &.blur {
    background: rgba(0, 0, 0, 0.5);
    // backdrop-filter: blur(4px);
    z-index: 200;
  }

  .message {
    position: absolute;
    margin: auto auto auto auto;
    font-size: 20px;
    background-color: #040612bf;
    padding: 10px 20px;
    border-radius: 24px;
    margin-top: 200px;
  }
}
