@import "src/assets/variables";

.wrapper {
  .title {
    font-size: $fontL;
    line-height: 30px;
    margin-bottom: 40px;
  }

  .amountInfo {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0px;

    .label {
      font-size: $fontS;
      color: $colorOptimGray200;
      margin-bottom: 5px;
    }

    .amount {
      width: 200px;

      .amountVal {
        font-size: $fontXxL;
        color: $colorBaseWhite;
        line-height: 38px;
      }
    }

    .info {
      display: block;
      text-align: right;

      .label {
        margin-bottom: 10px;
      }

      .infoAmount {
        font-size: $fontS;
        color: $colorBaseWhite;
      }
    }
  }

  .walletAmount {
    width: 100%;
    padding: 30px 0px;
    display: flex;
    justify-content: space-between;
    font-size: $fontS;
    color: $colorOptimGray200;
  }

  .btnWrapper {
    margin: 40px 0px;
    .button {
      width: 100%;
    }
  }

  .label {
    display: flex;
    align-items: center;
    color: $colorOptimGray200;
    line-height: 10px;
    font-size: $fontS;
  }

  .buyBtn {
    display: flex;
    padding: 10px 30px;

    &:after {
      position: relative;
      display: inline-block;
      left: 8px;
      top: 3px;
      content: url("../../../assets/icons/li_arrow-right-light.svg");
    }
  }

  .mention {
    font-size: $fontS;
    margin-bottom: 20px;
  }
}
