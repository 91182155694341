
@import "src/assets/variables";

.inputWrapper {
  width: 200px;
  height: 50px;
  border-radius: 24px;
  border: 1px solid $colorShade90;
  padding: 0 $gapM;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.green {
    border: 1px solid $colorGreen;

    .input {
      color: $colorGreen;
    }

    .inputNote {
      color: $colorGreen;
    }
  }

  &.fullWidth {
    width: 100%;
  }
}

.input {
  background: transparent;
  border: none;
  outline: none;
  color: $colorBaseWhite;
  font-size: $fontM;
  // width: 90px;
  width: 127px;

  &::-webkit-outer-spin-button, 
  &::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
}

.inputNote {
  color: $colorBaseWhite;
}
