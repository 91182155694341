@import "src/assets/variables";

.title {
  font-weight: $regular;
  font-size: $fontL;
  margin-bottom: 40px; 
}

.fieldLabel {
  font-size: $fontS;
  color: $colorOptimGray200;
  display: flex;
  align-items: center;
  
  &.textRight {
    justify-content: flex-end;
  }
}

.fieldValue {
  font-size: $fontM;
  font-weight: $medium;
  line-height: 24px;
  
  &.small {
    font-size: $fontS;
    font-weight: $regular;
  }
}

.sliderWrapper {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 32px;
}

.amountValueWrapper {
  display: block;
  text-align: right;
}

.amountValue {
  font-size: $fontXxL;
  margin-top: 8px;
  display: block;
}

.availableLabelWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.interestFieldsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.interestField {
  width: calc(50% - 10px);
}

.interestLabel {
  font-size: $fontS;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
