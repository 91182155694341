@import "src/assets/variables";

.cardBorder {
  border-radius: 12px;
  border: double 2px transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-bottom: 16px;
  transition: 0.4s;

  &:hover {
    background: linear-gradient(#15151E, #15151E) padding-box, linear-gradient(200deg, #9792E9, #2A54EB) border-box;
  }
}
.dangerBorder{
  border: 2px solid $colorRed;
  &:hover {
    border: double 2px transparent;
    background: linear-gradient(#15151E, #15151E) padding-box, linear-gradient(200deg, #9792E9, #2A54EB) border-box;
  }
}

.cardWrapper {
  background: radial-gradient(86.72% 63.17% at 3.74% 100%, rgba(44, 56, 75, 0.3) 0%, #1A1A25 100%) padding-box;
  border-radius: 12px;
  padding: $gapML;

  @media only screen and (max-width: $breakpointPhone) {
    width: 100%;
  }
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
  padding-left: 40px;
  position: relative;

  &:before {
    left: 0;
    top: 3px;
    position: absolute;
  }

  &.cardano {
    &:before {
      content: url("../../assets/icons/circle-card.svg");
    }
  }

  &.diamond {
    &:before {
      content: "";
      display: block;
      width: 34px;
      height: 34px;
      background-size: 34px 34px;
      background-image: url("../../assets/icons/diamond-512.svg");
      background-repeat: no-repeat;
      // content: url("../../assets/icons/diamond-512.svg");
    }
  }

  &.sphere {
    &:before {
      content: "";
      display: block;
      width: 34px;
      height: 34px;
      background-size: 34px 34px;
      background-image: url("../../assets/icons/sphere-512.svg");
      background-repeat: no-repeat;
      // content: url("../../assets/icons/sphere-512.svg");
    }
  }
}

.cardHeaderLabel {
  color: $colorOptimGray200;
  font-size: $fontS;
  font-weight: $regular;
  padding-bottom: $gapXxxs;
}

.cardHeaderValue {
  max-width: 9em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cardHeaderValueVerified {
}

.cardHeaderRate {
  font-size: $fontM;
}

.cardHeaderCol {
  &:nth-child(2) {
    text-align: right;
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
}

.detailsList {
  margin: 30px 0 0;
}

.detailsListItem {
  display: flex;
  justify-content: space-between;
  font-weight: $regular;
  font-size: $fontS;
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.detailName {
  color: $colorOptimGray200;
  display: flex;
  align-items: center;
}

.detailValue {
  color: $colorBaseWhite;

  &.green {
    color: $colorGreen;
  }
  
  &.red {
    color: $colorError700;
  }
}

.detailCopy {
  color: $colorBaseWhite;
  cursor: pointer;

  &:after {
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: 2px;
    content: url("../../assets/icons/copyID.svg");
  }
}

.colorLine {
  background: #3B3B44;
  width: 100%;
  height: 4px;
}

.colorLineCurrent {
  height: 100%;
  background: linear-gradient(90deg, #24FF96 0%, #4BFFC9 100%);

  &.violet {
    background: linear-gradient(90deg, #2A54EB 0%, #9C95E9 100%);
  }
}

.separator {
  height: 1px;
  width: 100%;
  background: $colorShade90;
}
.detailsButton {
  position: absolute;
  right: 0;
  top: 0;
  display: none;

  &:hover {
    svg * {
      fill: $colorBaseWhite;
    }
  }
  &.isHover {
    display: inline-flex;
  }
}

.buttonArrowIcon {
  position: relative;
  top: 3px;
  left: 4px;
}
