@import "src/assets/variables";
@import "src/assets/base/shared.scss";

.bondsContainer {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 70px 0;

  @media only screen and (max-width: $breakpointTablet) {
    padding: $gapXL $gapS;
  }
}

.bondsHeader {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 60px;

  @media only screen and (max-width: $breakpointTablet) {
    margin-bottom: 30px;
  }
}

.headerPanel {
  padding: $gapLL;
  border-radius: 12px;

  &.description {
    width: calc(100% - 366px);
    background: radial-gradient(
      86.28% 56.14% at 97.12% 3.58%,
      rgba(36, 21, 32, 0.62) 0%,
      #1a1a25 100%
    );
    padding-left: 190px;
    position: relative;

    &:before {
      content: "";
      display: inline-block;
      background-image: url("../../assets/icons/diamond-512.svg");
      background-size: 120px 120px;
      width: 120px;
      height: 120px;

      position: absolute;
      left: 30px;
      top: 30px;
      // content: url("../../assets/images/gem.svg");
    }
  }

  &.proposal {
    width: 350px;
    background: radial-gradient(
      86.72% 63.17% at 3.74% 100%,
      rgba(21, 38, 46, 0.35) 0%,
      #1a1a25 100%
    );
    text-align: center;
    padding: $gapLL $gap4XL;
  }

  @media only screen and (max-width: $breakpointTablet) {
    margin-bottom: 16px;

    &.description {
      width: 100%;
    }

    &.proposal {
      width: 100%;
    }
  }

  @media only screen and (max-width: $breakpointPhone) {
    &.description {
      padding-left: $gapLL;
      text-align: center;
      &:before {
        position: static;
      }
    }
  }
}

.panelTitle {
  font-size: $fontL;
  font-weight: $medium;
  margin: 0 0 10px;
}

.panelText {
  color: $colorOptimGray200;
  line-height: 24px;
}

.panelButton {
  margin-top: 20px;
}

.panelLink {
  display: inline-block;
  font-weight: $medium;
  color: $colorBaseWhite;
  margin-top: 10px;
  cursor: pointer;
}

.cardButton {
  width: 100%;
}

.buttonArrowIcon {
  position: relative;
  top: 3px;
  margin-left: 8px;
}

.activeBond {
  display: flex;
  justify-content: space-between;
  background: $gradiantBg4;
  border-radius: 12px;
  padding: $gapML;
  margin-bottom: $gapS;

  .left {
    display: flex;
    align-items: center;
    .bondIcon{
      margin-right: $gapS;
    }
    h3 {
      font-weight: $semiBold;
      font-size: $fontL;
      line-height: $fontXxL;
    }
    p {
      margin-top: $fontXxs;
      font-weight: $light;
      font-size: $fontM;
      line-height: $fontXL;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: $gapXs;

    li {
      color: $colorBaseWhite;
      margin-bottom: $gapMxs;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
// break class name
// container class name
// page class name
// active class name
// previous class name
// next class name
// disabled class name
.pagesContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $gapXs;
}
.activePageButton a {
  font-weight: bold;
  color: $colorBaseWhite;
}
.pageButton {
  border-radius: 12px;
  background: radial-gradient(86.72% 63.17% at 3.74% 100%, rgba(21, 38, 46, 0.35) 0%, #1A1A25 100%);
  background-color: $colorBaseBlack;
  padding: $gapS;
}
