@import "src/assets/variables";

.colorLine {
  background: #3B3B44;
  width: 100%;
  height: 4px;
}

.colorLineCurrent {
  height: 100%;
  background: linear-gradient(90deg, #24FF96 0%, #4BFFC9 100%);

  &.violet {
    background: linear-gradient(90deg, #2A54EB 0%, #9C95E9 100%);
  }
}

.cardContainer {
  border-radius: 24px;
  background: $colorShade110;
  padding: $gapM;
  margin-bottom: $gapM;

  h2 {
    font-size: $fontL;
    margin-bottom: $gapLL;
  }
  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
    }
    .main{
      display: flex;
      align-items: center;
    }
    .cardanoIcon {
      height: 30px;
    }
    .mainInfo {
      margin-left: $gapMxs;
      p:first-child {
        font-size: $fontS;
        color: $colorOptimGray200;
        margin-bottom: $gapXxxs;
      }
      p:nth-child(2) {
        max-width: 10em;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: $fontM;
        color: $colorBaseWhite;
        font-weight: $medium;
        margin: $gapNone;
      }
    }
    .right {
      text-align: right;
    }
  }
  .statusBar {
    height: 4px;
    width: 100%;
    .status {
      height: 4px;
      margin: $gapLL 0;
    }
  }
  .divider {
    height: 1px;
    background: $colorShade90;
    margin: $gapLL $gapNone;
  }
  .btn {
    button {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      &:first-child {
        margin-bottom: 10px;
      }
      svg{
        margin-top: 5px;
        margin-left: 11px;
      }
      .depositBtn{
        svg *{
          fill: white;
        }
      }
    }
  }
}
