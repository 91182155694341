@import "src/assets/variables";
.redeemTitle{
  font-size: $fontL;
  font-weight: $regular;
}
.icons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
  margin: $gap3XL auto;
}
.reward {
  background: $colorShade110;
  border: 1px solid $colorShade90;
  border-radius: 24px;
  margin-top: $gap3XL;
  padding: 23px $gapLL;
  display: flex;
  justify-content: space-between;

  p {
    color: $colorBaseWhite;
    font-size: $fontM;
  }
  .text{
    color: $colorOptimGray200;
    margin-bottom: 4px;
  }
  .rightSide{
    display: flex;
    align-items: center;
    text-align: right;
    .cardanoIcon{
      margin-left: $gapM;
    }
  }
}
