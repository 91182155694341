@import "src/assets/base/shared.scss";
@import "src/assets/variables";

// Add Bond Container scss
.container {
  max-width: 1024px;
  padding-top: 61px;
  margin: auto;
  padding-bottom: 200px;

  .cardWrapper {
    display: flex;
    gap: 16px;
    color: $colorBaseWhite;
    font-size: $gapS;
    
    p {
      margin-top: 5px;
    }
    .title {
      font-size: $gapM;
      color: $colorBaseWhite;
      margin: 0
    }

    .description {
      color: $colorOptimGray200;
      margin-top: 8px;
    }

    .bondCardWrapper {
      padding: 30px;
      display: flex;
      border-radius: 12px;
      gap: 40px;
      background: radial-gradient(86.28% 56.14% at 97.12% 3.58%, rgba(36, 21, 32, 0.62) 0%, #1A1A25 100%);
      width: 100%;
    }
  
    .issueBondCardWrapper {
      padding: 30px;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      background: radial-gradient(86.72% 63.17% at 3.74% 100%, rgba(21, 38, 46, 0.35) 0%, #1A1A25 100%);
      border-radius: 12px;
      min-width: 350px;

      .button {
        & * {
          width: 135px;
          justify-content: center;
          font-size: $gapS;
          background: $colorBaseWhite;
          color: $colorShade90;
          font-weight: bold;
        }

        .panelButton {
          margin-top: 20px;
        }
      }
    }
  }

  .head_title {
    font-size: $gap2XL;
    margin: 0;
    margin-top: 40px;
    font-weight: 600;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  p {
    line-height: 24px;
  }

  p.title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $colorBaseWhite;
    margin-bottom: 16px;
    margin-top: 40px;
  }

  .interestRateTitle {
    display: flex;
    justify-content: space-between;
  }

  .interestRateRadioButton {
    display: flex;
    margin-left: $gapXs;
    align-items: center;
  }

  .interestRateAttention {
    margin-top: $gapS;
    margin-bottom: $gapS;
  }

  &.ul {
    list-style-type: none;
    display: flex;
    align-items: center;

    li {
      text-transform: capitalize;
      position: relative;
      color: $colorGray200;
      padding: 10px;

      cursor: pointer;

      &:hover {
        color: $colorBaseWhite;
      }
    }
  }

  li:not(:last-child):after {
    content: "";
    position: absolute;
    background: $colorGray600;
    width: 1px;
    margin-left: 10px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .active {
    color: $colorBaseWhite;
  }

  .gray {
    color: $colorOptimGray200;
  }

  .ml_10 {
    margin-left: 10px;
  }

  .box {
    padding: 16px 20px;
    border-radius: 24px;
    align-items: center;
    display: flex;
    background-color: $colorShade110;
    justify-content: space-between;

    .connectionMark {
      padding: 2px 8px;
      font-size: $fontS;
      height: fit-content;
      background: $bgColorSuccess400;
      color: $colorSuccess400;
      border-radius: 16px;
      line-height: 18px;
    }
  }

  .inputBox {
    outline: none;
    border: none;
    color: $colorGray200;
    width: 100%;
    font-size: $gapS;
    height: 50px;
  }

  .bondBodyWrapper {
    display: flex;
    gap: 40px;
    margin-top: 30px;

    .boundCardWrapper {
      min-width: 540px;
    }
  }

  .itemsContainer {
    display: flex;
    gap: 10px;

    .box {
      display: block;
      text-align: center;
      cursor: pointer;
      border-radius: 12px;
      width: 100%;
      p {
        margin: 0px;
      }
      .title {
        font-size: $gapS;
      }
      .label {
        font-size: 14px;
        color: $colorOptimGray200;
        margin-top: 4px;
      }
    }
    .box.active {
      background: $colorBaseWhite;
      p {
        color: $colorOptimGray900;
      }
    }
    .box:hover{
      background: $colorShade100;
    }
  }

  .interestWrapper {
    display: flex;
    gap: 40px;
    white-space: nowrap;
    min-width: 350px;

    .interestRateBox {
      padding: 16px 20px;
      border-radius: 24px;
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $colorShade110;
      p:nth-child(2) {
        margin-top: 4px;
      }
    }

    .interestRateInput {
      width: 100%;
      padding: 10px 0px 10px 13px;
      margin: 0;

      &:focus {
        border: 0px;
      }
    }

    .info {
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 60%;
      p {
        font-size: $fontS;
        margin-bottom: $gapXxs;
      }
      .rate {
        font-size: $fontXxL;
      }
    }
  }

  .interestRateInputUnit {
    position: absolute;
    top: 13px;
    right: 4px;
  }

  .premiumWrapper {
    display: flex;
    gap: 40px;
    white-space: nowrap;
    margin-bottom: $gapXs;

    .premiumBox {
      padding: 16px 20px;
      border-radius: 24px;
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $colorShade110;
      p:nth-child(2) {
        margin-top: 4px;
      }
    }

    .info {
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 60%;
      p {
        font-size: $fontS;
        margin-bottom: $gapXxs;
      }
      .rate {
        font-size: $fontXxL;
      }
      .reqPrem {
        margin-bottom: 0;
      }
      .epochConversion {
        margin-bottom: 0;
      }
    }
  }

  .dontCreatePoolWrapper {
    display: flex;
    gap: $gapXxs;

    .checkbox {
      margin-left: $gapXxs;
    }
  }

  .symbol {
    position: absolute;
    top: 16px;
    right: 20px;
  }

  .bondTypeWrapper {
    display: flex;
    gap: 16px;

    p,
    * {
      color: $colorOptimGray200;
    }

    .types {
      width: 100%;
    }
  }

  .tabsContent {
    margin-top: 60px;

    .whiteButton {
      background: $colorBaseWhite;
      color: $colorShade90;
    }

    .ghostButton {
      color: $colorBaseWhite;
      background: none;
      border: 1px solid $colorShade90;
    }

    .openBtnWrapper {
      display: flex;
      gap: 12px;
    }
  }
  .interestBufferAttention {
    margin-top: $gapS;
    margin-bottom: 0;
  }
  .cancelAttention {
    margin-top: $gapS;
    margin-bottom: $gapS;
  }
  .advancedOptionsItem {
    padding: 0;
  }
  .advancedOptionsSection {
    padding: 0;
    border-bottom: 0;
  }
}

.bondAmountSlider {
  display: flex;
  gap: 10px;

  .box {
    display: block;
    text-align: center;
    border-radius: 12px;
    width: 100%;
    p {
      margin: 0px;
    }
    .title {
      font-size: $gapS;
    }
    .label {
      font-size: 14px;
      color: $colorOptimGray200;
      margin-top: 4px;
    }
  }
}

