@import "src/assets/variables";

.cancelBtn {
  background: $colorRed;
  margin-top: $gap3XL;
}

.warning {
  background-color: rgba(247, 144, 9, 0.19);
  display: flex;
  flex-direction: row;
  padding: 16px;
  gap: 12px;
  border-radius: 30px;
  margin: $gap3XL 0;
  height: 72px;

  p{
    color: $colorWarning;
    font-size: $fontS;
  }
}

.total{
  display: flex;
  justify-content: space-between;
  border: 1px solid #3C3C55;
  background: #1A1A25;
  border-radius: 24px;
  align-items: center;
  padding: 20px 30px;
  p{
    font-size: $fontM;
    &:first-child{
      color: $colorOptimGray200;
    }
  }
}
