.horizontal-slider {
  width: 100%;
  margin: auto;
  height: 30px;
}

.thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #8b8cea;
  border: 1px solid #8b8cea;
  border-radius: 100%;
  height: 14px !important;
  width: 14px !important;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.thumb.active {
  background-color: #8b8cea;
}

.track {
  position: relative;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 100px;
}

.track.track-0 {
  background-image: linear-gradient(to right, #2853eb, #9c95e9);
  border-radius: 100px;
}

.horizontal-slider .track {
  top: 20px;
  height: 4px;
}

.horizontal-slider .thumb {
  top: 15px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}
